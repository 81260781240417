<template>
    <el-table v-if="tableData.length > 0" v-loading="$wait.is('loading.categories')" :data="tableData" class="w-full" element-loading-spinner="el-custom-spinner">
        <el-table-column :label="$t('services.name')" prop="name" :show-overflow-tooltip="true" />
        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="left">
                        <el-button type="primary" size="mini" :disabled="!userCan('update service categories')" @click="handleEdit(scope.row.id)">
                            <fa-icon :icon="['far', 'edit']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete service categories')" @click="handleDelete(scope.row.id)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(id) {
            this.$emit('handleEdit', id);
        },

        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
    },
};
</script>
